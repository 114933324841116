<template>
  <div v-if="isLogin">
    <v-app id="inspire">
      <div v-if="!isAplication">
        <v-navigation-drawer v-model="drawer" app>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h6">{{ name }}</v-list-item-title>
              <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list dense nav>
            <v-list-item
              v-for="(application, index) in applications"
              :key="index"
              link
              @click="getChats(index)"
            >
              <v-list-item-icon>
                <img :src="application.icon" class="logoApplications" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ application.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </div>
      <div v-else>
        <v-navigation-drawer v-model="drawer" app>
          <v-list-item>
            <v-btn icon @click="isAplication = !isAplication">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item>

          <v-divider></v-divider>

          <v-list dense nav>
            <v-list-item
              v-for="(chat, index) in chats"
              :key="index"
              link
              @click="
                getChatHistory(
                  chat.index,
                  chat.alias,
                  chat.title,
                  chat.room,
                  index
                )
              "
            >
              <v-list-item-icon>
                <v-icon>{{ chat.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ chat.title }}</v-list-item-title>
                <v-list-item-subtitle class="fontSize8"
                  >{{ chat.startedAt }}-{{
                    chat.expiresAt
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>

              <v-list-item-action-text v-if="chat.messages_not_read > 0">
                {{ chat.messages_not_read }}
              </v-list-item-action-text>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </div>

      <v-app-bar
        app
        :style="{ background: headerBackground ? headerBackground : '' }"
      >
        <v-app-bar-nav-icon
          :style="{ color: headerBackground ? 'white' : '' }"
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>
        <v-toolbar-title :style="{ color: headerBackground ? 'white' : '' }">{{
          title
        }}</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="logout()"
          :style="{ color: headerBackground ? 'white' : '' }"
        >
          <v-icon>mdi-logout-variant</v-icon>
        </v-btn>
      </v-app-bar>

      <v-main>
        <div v-if="!messages.length">
          <div class="pa-4">
            <div class="wrapper">Выберите один из чатов</div>
          </div>
        </div>
        <div v-else>
          <div class="pa-4">
            <div class="wrapper">
              <div v-for="(message, index) in messages" :key="index">
                <div v-if="message.type == 'outcome'">
                  <v-row justify="end">
                    <div
                      class="message"
                      :style="{
                        background: messageBackground,
                      }"
                    >
                      <div class="messageTime">{{ message.time }}</div>
                      <div v-html="message.text.replace(/(\n)|(\r)/g, '<br />')">{{ message.text }}</div>
                    </div>
                  </v-row>
                </div>
                <div v-else>
                  <v-row>
                    <div class="message">
                      <div class="messageTime">{{ message.time }}</div>
                      <div v-html="message.text.replace(/(\n)|(\r)|(\r)/g, '<br />')">{{ message.text }}</div>
                    </div>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
          <v-footer>
            <v-col cols="12">
              <v-textarea
                rows="1"
                auto-grow
                label="Введите сообщение"
                clearable
                v-model="message"
                color="darken darken-2"
                v-on:keyup.enter="sendMessage"
                append-outer-icon="mdi-send"
                @click:append-outer="sendMessage"
              ></v-textarea>
            </v-col>
          </v-footer>
        </div>
      </v-main>
    </v-app>
  </div>
  <div v-else>
    <v-app id="inspire">
      <v-app id="inspire">
        <v-main>
          <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="8" md="4">
                <v-row align="center" justify="center">
                  <img :src="imageLogo" class="logo" />
                </v-row>
                <v-card class="elevation-12">
                  <v-toolbar class="backgroundBrand">
                    <v-toolbar-title class="colorWhite"
                      >Авторизация</v-toolbar-title
                    >
                  </v-toolbar>
                  <v-card-text>
                    <v-form>
                      <v-text-field
                        label="Логин"
                        v-model="login"
                        prepend-icon="mdi-account"
                        type="text"
                        color="darken darken-2"
                      ></v-text-field>

                      <v-text-field
                        label="Пароль"
                        v-model="password"
                        prepend-icon="mdi-lock"
                        type="password"
                        color="darken darken-2"
                      ></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="auth()">Войти</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-main>
      </v-app>
    </v-app>
  </div>
</template>

<script>
export default {
  data: () => ({
    imageLogo: require("./assets/images/logo.png"),
    indexApplication: null,
    indexChat: null,
    headerBackground: null,
    messageBackground: null,
    soсket0: null,
    soсket1: null,
    soсket2: null,
    login: "",
    password: "",
    isLogin: true,
    name: "",
    email: "",
    user0: "", //пользователь 0 приложения
    user1: "", //пользователь 1 приложения
    user2: "", //пользователь 2 приложения
    title: "",
    drawer: null,
    message: "",
    isAplication: false,
    applications: [
      {
        title: "Отношения",
        icon: require("./assets/images/icognito.png"),
        messageBackground: "#D0DFFF",
        headerBackground: "#3E77F3",
      },
      {
        title: "Антидепрессия",
        icon: require("./assets/images/antidepression.png"),
        messageBackground: "#E4DBFF",
        headerBackground: "#735DB0",
      },
      {
        title: "Антистресс",
        icon: require("./assets/images/antistress.png"),
        messageBackground: "#FFDFCA",
        headerBackground: "#E98948",
      },
    ],
    chats: [],
    messages: [],
    paths: [
      {
        pathAuth: "https://icognito.vmcrm.ru:8080/api/oauth",
        pathChats: "https://icognito.vmcrm.ru:8080/api/chatlist",
        pathChatHistory: "https://icognito.vmcrm.ru:8080/api/chathistory",
        pathSocket: "wss://icognito.vmcrm.ru:8080/wsw/?u_from=",
        pathSendMessage: "https://icognito.vmcrm.ru:8080/chat/send",
      },
      {
        pathAuth: "https://depression.icognito.app/api/oauth",
        pathChats: "https://depression.icognito.app/api/chatlist",
        pathChatHistory: "https://depression.icognito.app/api/chathistory",
        pathSocket: "wss://depression.icognito.app/ws/?u_from=",
        pathSendMessage: "https://depression.icognito.app/chat/send",
      },
      {
        pathAuth: "https://antistress2.icognito.app/api/oauth",
        pathChats: "https://antistress2.icognito.app/api/chatlist",
        pathChatHistory: "https://antistress2.icognito.app/api/chathistory",
        pathSocket: "wss://antistress2.icognito.app/ws/?u_from=",
        pathSendMessage: "https://antistress2.icognito.app/chat/send",
      },
    ],
  }),
  created() {
    this.isLogin = localStorage.getItem("isLogin");
    this.user0 = JSON.parse(localStorage.getItem("user0"));
    this.user1 = JSON.parse(localStorage.getItem("user1"));
    this.user2 = JSON.parse(localStorage.getItem("user2"));

    if (this.user0) {
      this.email = this.user0.login;
      this.name = this.user0.name;
    }

    //Application 0
    if (this.user0) {
      this.socket0 = new WebSocket(this.paths[0].pathSocket + this.user0.alias);

      this.socket0.onopen = () => {
        console.log('Сокет 0 успешно открыт');
      };

      this.socket0.onmessage = (event) => {
        this.onMessage(event);
        console.log('Сокет 0 сообщение' + JSON.stringify(event.data));
      };

      this.socket0.onclose = (event) => {
        if (!event.wasClean) {
          this.socket0 = new WebSocket(
            this.paths[0].pathSocket + this.user0.alias
          );
        }
      };

      this.socket0.onerror = () => {
        this.socket0 = new WebSocket(
          this.paths[0].pathSocket + this.user0.alias
        );
      };
    }
    //Application 1
    if (this.user1) {
      this.socket1 = new WebSocket(this.paths[1].pathSocket + this.user1.alias);

      this.socket1.onopen = () => {
        console.log('Сокет 1 успешно открыт');
      };

      this.socket1.onmessage = (event) => {
        this.onMessage(event);
        console.log('Сокет 1 сообщение' + JSON.stringify(event.data));
      };

      this.socket1.onclose = (event) => {
        if (!event.wasClean) {
          this.socket1 = new WebSocket(
            this.paths[1].pathSocket + this.user1.alias
          );
        }
      };

      this.socket1.onerror = () => {
        this.socket1 = new WebSocket(
          this.paths[1].pathSocket + this.user1.alias
        );
      };
    }
    //Application 2
    if (this.user2) {
      this.socket2 = new WebSocket(this.paths[2].pathSocket + this.user2.alias);

      this.socket2.onopen = () => {
        console.log('Сокет 2 успешно открыт');
      };

      this.socket2.onmessage = (event) => {
        this.onMessage(event);
        console.log('Сокет 2 сообщение' + JSON.stringify(event.data));
      };

      this.socket2.onclose = (event) => {
        if (!event.wasClean) {
          this.socket2 = new WebSocket(
            this.paths[2].pathSocket + this.user2.alias
          );
        }
      };

      this.socket2.onerror = () => {
        this.socket2 = new WebSocket(
          this.paths[2].pathSocket + this.user2.alias
        );
      };
    }
  },
  methods: {
    getChats: function (index) {
      var path = this.paths[index].pathChats;
      var token = "";

      switch (index) {
        case 0:
          token = this.user0.token;
          break;
        case 1:
          token = this.user1.token;
          break;
        case 2:
          token = this.user2.token;
          break;
      }

      this.chats = [];
      this.messages = [];
      this.title = "";
      this.indexChat = null;
      this.indexApplication = index;

      this.headerBackground = this.applications[index].headerBackground;
      this.messageBackground = this.applications[index].messageBackground;

      this.$axios
        .post(path, {
          user_id: token,
        })
        .then((response) => {
          if (response.data.users) {
            response.data.users.forEach((element) => {
              this.chats.push({
                index: index,
                title: element.name,
                alias: element.alias,
                room: element.room,
                icon: "mdi-account-multiple",
                messages_not_read: 0,
                startedAt: element.started_at,
                expiresAt: element.expires_at,
              });
            });

            // this.chats.sort((a, b) => (a.startedAt < b.startedAt ? 1 : -1));
          }
          this.isAplication = !this.isAplication;
        })
        .catch((error) => {
          alert("Не удалось получить чаты по приложению");
          console.log("getChats", error);
        });
    },
    getChatHistory: function (index, alias, title, room, chatIndex) {
      var path = this.paths[index].pathChatHistory;
      var token = "";

      switch (index) {
        case 0:
          token = this.user0.alias;
          break;
        case 1:
          token = this.user1.alias;
          break;
        case 2:
          token = this.user2.alias;
          break;
      }

      this.messages = [];
      this.title =
        "Чат с пользователем " +
        title +
        " (Подписка с " +
        this.chats[chatIndex].startedAt +
        " по " +
        this.chats[chatIndex].expiresAt +
        ")";
      this.indexChat = chatIndex;
      this.chats[chatIndex].messages_not_read = 0;

      this.$axios
        .post(path, {
          user_id: token,
          to_id: alias,
        })
        .then((response) => {
          if (response.data.length > 0) {
            response.data.forEach((element) => {
              this.messages.push({
                text: element.message,
                time: element.created,
                type: token == element.from_id ? "outcome" : "income",
              });
            });

            this.messages.sort((a, b) => (a.time > b.time ? 1 : -1));

            this.$nextTick(function () {
              this.scrollToEnd();
            });
          } else {
            alert("История чата пуста");
          }
        })
        .catch((error) => {
          alert("Не удалось получить историю чатов по приложению");
          console.log("getChatHistory", error);
        });
    },
    onMessage: function (event) {
      var data = JSON.parse(event.data);
      var alias = "";
      var checkChat = "";

      switch (this.indexApplication) {
        case 0:
          alias = this.user0.alias;
          break;
        case 1:
          alias = this.user1.alias;
          break;
        case 2:
          alias = this.user2.alias;
          break;
      }

      this.chats.forEach((chat, index) => {
        if (chat.room == data.room) {
          checkChat = index;

          if (index != this.indexChat) {
            chat.messages_not_read++;
          }
        }
      });

      if (checkChat == this.indexChat) {
        this.messages.push({
          text: data.message,
          time: data.created,
          type: alias == data.from ? "outcome" : "income",
        });
      }

      this.$nextTick(function () {
        this.scrollToEnd();
      });
    },
    sendMessage: function (event) {
      if (event.shiftKey===true && event.key === "Enter") {
        return;
      }
      if (this.message.trim().length > 0) {
        var path = this.paths[this.indexApplication].pathSendMessage;
        var alias = this.chats[this.indexChat].alias;
        console.log("Приложение " + this.indexApplication);
        console.log("Чат " + this.indexChat);
        var token = "";
        console.log(path);
        console.log(alias);
        switch (this.indexApplication) {
          case 0:
            token = this.user0.alias;
            break;
          case 1:
            token = this.user1.alias;
            break;
          case 2:
            token = this.user2.alias;
            break;
        }

        this.$axios
          .post(path, {
            from: token,
            to: alias,
            message: this.message.trim(),
          })
          .then(() => {
            this.$nextTick(function () {
              this.scrollToEnd();
            });
          })
          .catch((error) => {
            alert("Не удалось отправить сообщение по токену " + token + " алиасу " + alias);
            console.log("sendMessage", error);
          });

        this.message = "";
      } else {
        this.message = "";
      }
    },
    auth: function () {
      if (this.login != "" && this.password != "") {
        this.paths.forEach((element, index) => {
          var userAlias = "user" + index;
          this.$axios
            .post(element.pathAuth, {
              login: this.login,
              password: this.password,
            })
            .then((response) => {
              this.name = response.data.name;
              this.email = response.data.login;
              this.isLogin = true;
              localStorage.setItem("isLogin", true);
              localStorage.setItem(userAlias, JSON.stringify(response.data));

              switch (index) {
                case 0:
                  this.user0 = response.data;
                  break;
                case 1:
                  this.user1 = response.data;
                  break;
                case 2:
                  this.user2 = response.data;
                  break;
              }
            })
            .catch((error) => {
              alert("Не получить авторизованного пользователя");
              console.log("auth", error);
            });
        });
      }
    },
    logout: function () {
      this.isLogin = false;
      localStorage.removeItem("isLogin");
      localStorage.removeItem("user0");
      localStorage.removeItem("user1");
      localStorage.removeItem("user2");
      document.location.reload();
    },
    scrollToEnd: function () {
      var container = this.$el.querySelector(".wrapper");
      container.scrollTop = container.scrollHeight;
    },
  },
};
</script>

<style scoped>
* {
  color: #09101d;
  font-family: "Open Sans", sans-serif;
}

.fontSize8 {
  font-size: 8px !important;
}

.colorWhite {
  color: white !important;
}

.backgroundBrand {
  background: #18c6e1 !important;
}

.logoApplications {
  width: 25px;
  height: 25px;
}

.logo {
  width: 60%;
  margin-top: -100px;
  margin-bottom: 50px;
}

.modal {
  min-width: 320px;
  width: 400px;
  height: 300px;
  padding: 20px;
}

.wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 202px);
}

.message {
  min-width: 200px;
  background: #f5f5f5;
  max-width: 800px;
  width: max-content;
  padding: 30px 30px 20px 20px;
  font-size: 12px;
  border-radius: 5px;
  margin: 10px 20px 10px 0px;
  word-wrap: break-word;
  position: relative;
  overflow: hidden;
}

.messageTime {
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 10px;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import io from "socket.io-client";

Vue.prototype.$axios = axios;
Vue.prototype.$io = io;

new Vue({
    vuetify,
    render: h => h(App)
}).$mount('#app');
